import React from 'react';
import headerImg from '../images/moments.png';

function Moments() {
  return (
      <div>
        <img src={headerImg} alt="" class="pageImage"/>
        <div class="page-content moments">
          <h2>Moments</h2>

          <h3>What is Moments?</h3>
          <p>Take your own DPEK Moments on Instagram with hashtag #dpek2025 to share your photos with others DPEK attendees.</p>
          <div class="elfsight-app-b4520684-7a4f-4c19-bb0f-d08f00e3fe83" data-elfsight-app-lazy></div>
        </div>
      </div>
    );
}

export default Moments;
