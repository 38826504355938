import React from 'react';
import dpekLogo from '../images/header.png';

function header() {
  return (
    <div id="header">
      <img src={dpekLogo} alt="DPEK Logo" />
    </div>
    );
}

export default header;
