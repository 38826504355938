import React from 'react';
import headerImg from '../images/travel.png';

function Travel() {
  return (
      <div>
        <img src={headerImg} alt="" class="pageImage"/>
        <div class="page-content travel">
          <h2>Travel</h2>

          <h3>Arival Airport</h3>
          <p>OHare International Airport</p>
          <p>10000 West Ohare Ave</p>
          <p>Chicago, Il 60666</p>

          <h3>Ride Share</h3>
          <p>Ride share is located outside of terminal X. Please attempt to travel together to save costs. Most people should be arriving between 11am and 2pm. Look for those with DFIN swag.</p>

          <h3>The Venue</h3>
          <p>Hilton Rosemont Chicago Ohare</p>
          <p>5550 N River Rd</p>
          <p>Chicago, Il 60018</p>
          <p><a href="tel:1 844 209 0789">+1 844 209 0789</a></p>
        </div>
      </div>
    );
}

export default Travel;
