import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, NavLink } from 'react-router-dom';
import './App.css';


import DPEK from './pages/DPEK';
import Agenda from './pages/Agenda';
import Travel from './pages/Travel';
import Moments from './pages/Moments';
import Help from './pages/Help';

import Header from './components/header';

import navDPEK from './images/nav-dpek.svg';
import navAgenda from './images/nav-agenda.svg';
import navTravel from './images/nav-travel.svg';
import navMoments from './images/nav-moments.svg';
import navHelp from './images/nav-help.svg';

function App() {
  return (
    <Router>
      <Header />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<DPEK />} />
          <Route path="/agenda" element={<Agenda />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/moments" element={<Moments />} />
          <Route path="/help" element={<Help />} />
        </Routes>
      </div>
      <nav>
        <ul className="tab-list">
          <li>
            <NavLink to="/" activeClassName="active-link">
              <img src={navDPEK} alt="DPEK"/><br />
              DPEK
            </NavLink>
          </li>
          <li>
            <NavLink to="/agenda" activeClassName="active-link">
              <img src={navAgenda} alt="Agenda"/><br />
              Agenda
            </NavLink>
          </li>
          <li>
            <NavLink to="/travel" activeClassName="active-link">
              <img src={navTravel} alt="Travel"/><br />
              Travel
            </NavLink>
          </li>
          <li>
            <NavLink to="/moments" activeClassName="active-link">
              <img src={navMoments} alt="Moments"/><br />
              Moments
            </NavLink>
          </li>
          <li>
            <NavLink to="/help" activeClassName="active-link">
              <img src={navHelp} alt="Help"/><br />
              Help
            </NavLink>
          </li>
        </ul>
      </nav>
    </Router>
  );
}

export default App;
