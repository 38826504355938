import React from 'react';
import headerImg from '../images/dpek.png';

function DPEK() {
  return (
      <div>
        <img src={headerImg} alt="" class="pageImage"/>
        <div class="page-content dpek">
          <h2>DPEK</h2>

          <h3>Welcome</h3>
          <p>Welcome to DPEK 2025 back in Rosemont, Illinois. We continue to focus on our digital transformation as a key finanacial solutions provider to 
          some of the largest companies in the world. This year we will focus on the continuation of our transition to a SaaS software company through 
          improvements and enhancements in our current offerings which will bring more value to our customers and more value to our company through 
          customer retention. Enjoy the event! ~ Floyd Strimling</p>

          <h3>Sponsors</h3>
          <p>A special thank you to our esteemed sponsors. Please be sure to thank them when you see them.</p>
          <a href="#"><img src="" alt="Sponsor1"/></a> 
          <a href="#"><img src="" alt="Sponsor2"/></a> 
          <a href="#"><img src="" alt="Sponsor3"/></a> 
          <a href="#"><img src="" alt="Sponsor4"/></a> 
          <a href="#"><img src="" alt="Sponsor5"/></a>

          <h3>Attire</h3>
          <p>What to wear? Sneakers, jeans, and hoodies. Keep it casual... but not too casual.</p>
        </div>
      </div>
    );
}

export default DPEK;
