import React from 'react';
import headerImg from '../images/help.png';

function Help() {
  return (
      <div>
        <img src={headerImg} alt="" class="pageImage"/>
        <div class="page-content help">
          <h2>Help</h2>
          
          <h3>Acendas Corporate Travel</h3>
          <p>Phone <a href="tel:1 913 586 7931">+1 913 586 7931</a></p>
          <p>Email <a href="email:reservations@acendas.com">reservations@acendas.com</a></p>

          <h3>Airlines</h3>
          <p>American <a href="tel:1 800 433 7300">+1 800 433 7300</a></p>
          <p>Delta <a href="tel:1 800 455 2720">+1 800 455 2720</a></p>
          <p>Jet Blue <a href="tel:1 800 538 2583">+1 800 538 2583</a></p>
          <p>Southwest <a href="tel:1 800 435 9792">+1 800 435 9792</a></p>
          <p>United <a href="tel:1 800 864 8331">+1 800 864 8331</a></p>

          <h3>Hilton Rosemont</h3>
          <p>Phone <a href="tel:1 844 209 0789">+1 844 209 0789</a></p>

          <h3>DFIN Tech Care Center</h3>
          <p>Phone <a href="tel:">+1 </a></p>
          <p>Email <a href="email:dfintechcarecenter@dfinsolutions.com">dfintechcarecenter@dfinsolutions.com</a></p>

          <h3>SAP Concur Expenses</h3>
          <p>Website <a href="http://concursolutions.com">concursolutions.com</a></p>
          <p>
            <a href="#">App Store</a> 
            <a href="#">Google Play</a>
          </p>
        </div>
      </div>
    );
}

export default Help;
