import React from 'react';
import headerImg from '../images/agenda.png';

function Agenda() {
  return (
    <div>
      <img src={headerImg} alt="" class="pageImage"/>
      <div class="page-content agenda">
        <h2>Agenda</h2>
        <h3>Daily Schedule</h3>
        <ul class="agenda">
          <li class="day"><strong>Day 0 | Monday, January 27</strong>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
          </li>
          <li class="day"><strong>Day 1 | Tuesday, January 28</strong>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
          </li>
          <li class="day"><strong>Day 2 | Wednesday, January 29</strong>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
          </li>
          <li class="day"><strong>Day 3 | Thursday, January 30</strong>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
            <ul class="time">
              <li>2:00pm</li>
              <li>Registration Opens</li>
              <li>Rosemont Ballroom</li>
            </ul>
            <ul class="time">
              <li>6:00pm</li>
              <li>Welcome Reception/Diner</li>
              <li>Ventanas - 11th Floor</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    );
}

export default Agenda;
